import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/backoffice/login',
    name: 'Login',
    component: () => import('../views/Backoffice/BackofficeLogin.vue')
  },
  {
    path: '/backoffice',
    name: 'Login',
    component: () => import('../views/Backoffice/BackofficeLogin.vue')
  },
  {
    path: '/backoffice/home',
    name: 'Home',
    component: () => import('../views/Backoffice/BackofficeHome.vue')
  },
  {
    path: '/backoffice/logout',
    name: 'Logout',
    component: () => import('../views/Backoffice/BackofficeLogout.vue')
  },
  {
    path: '/backoffice/statistics',
    name: 'BackofficeStatistics',
    component: () => import('../views/Backoffice/BackofficeStatistics.vue')
  },
  {
    path: '/backoffice/stock_definitions',
    name: 'BackofficeStockDefinitions',
    component: () => import('../views/Backoffice/BackofficeStockDefinitions.vue')
  },
  {
    path: '/backoffice/stock_day',
    name: 'BackofficeStockDay',
    component: () => import('../views/Backoffice/BackofficeStockDay.vue')
  },
  {
    path: '/backoffice/top_five',
    name: 'BackofficeTopFive',
    component: () => import('../views/Backoffice/BackofficeTopFive.vue')
  },
  {
    path: '/backoffice/graphe_explanations',
    name: 'BackofficeGraphExplanations',
    component: () => import('../views/Backoffice/BackofficeGraphExplanations.vue')
  },
  {
    path: '/backoffice/edit_graphe_explanations',
    name: 'BackofficeEditGraphExplanations',
    component: () => import('../views/Backoffice/BackofficeEditGraphExplanations.vue')
  },
  {
    path: '/backoffice/disclaimer',
    name: 'BackofficeDisclaimer',
    component: () => import('../views/Backoffice/BackofficeDisclaimer.vue')
  },
  {
    path: '/backoffice/edit_disclaimer',
    name: 'BackofficeEditDisclaimer',
    component: () => import('../views/Backoffice/BackofficeEditDisclaimer.vue')
  },
  {
    path: '/backoffice/email_list',
    name: 'BackofficeEmaiLlist',
    component: () => import('../views/Backoffice/BackofficeEmaiLlist.vue')
  },
  {
    path: '/backoffice/admins',
    name: 'BackofficeAdmins',
    component: () => import('../views/Backoffice/BackofficeAdmins.vue')
  },
  {
    path: '/backoffice/add_admin',
    name: 'BackofficeAddAdmin',
    component: () => import('../views/Backoffice/BackofficeAddAdmin.vue')
  },
  {
    path: '/backoffice/backoffice_users',
    name: 'BackofficeUsers',
    component: () => import('../views/Backoffice/BackofficeUsers.vue')
  },
  {
    path: '/backoffice/wysiwyg',
    name: 'Wysiwyg',
    component: () => import('../views/Backoffice/BackofficeWysiwig.vue')
  },
  {
    path: '/backoffice/forgot_password',
    name: 'BackofficeForgotPassword',
    component: () => import('../views/Backoffice/BackofficeForgotPassword.vue')
  },
  {
    path : '/backoffice/backoffice_user_delete',
    name : 'BackofficeUserDelete',
    component : () => import('../views/Backoffice/BackofficeUserDelete.vue')
  },
  {
    path : '/backoffice/backoffice_user_edit',
    name : 'BackofficeUserEdit',
    component : () => import('../views/Backoffice/BackofficeUserEdit.vue')
  },
  {
    path : '/backoffice/backoffice_user_change_password',
    name : 'BackofficeUserChangePassword',
    component : () => import('../views/Backoffice/BackofficeUserChangePassword.vue')
  },
  {
    path : '/backoffice/file_uploader',
    name : 'BackofficeFileUploader',
    component : () => import('../views/Backoffice/BackofficeFileUploader.vue')
  },
  {
    path : '/backoffice/cloudinary_file_uploader',
    name : 'BackofficeCloudinaryFileUploader',
    component : () => import('../views/Backoffice/BackofficeCloudinaryFileUploader.vue')
  },
  {
    path : '/backoffice/s3_file_uploader',
    name : 'BackofficeS3FileUploader',
    component : () => import('../views/Backoffice/BackofficeS3FileUploader.vue')
  },
  {
    path : '/backoffice/payme',
    name : 'BackofficePayme',
    component : () => import('../views/Backoffice/BackofficePayme.vue')
  },
  {
    path : '/backoffice/payme_ty',
    name : 'BackofficePaymeTy',
    component : () => import('../views/Backoffice/BackofficePaymeTy.vue')
  },
  {
    path : '/backoffice/payme_order_delete',
    name : 'BackofficePaymeOrderDelete',
    component : () => import('../views/Backoffice/BackofficePaymeOrderDelete.vue')
  },
  {
    path : '/backoffice/payme_order_refund',
    name : 'BackofficePaymeOrderRefund',
    component : () => import('../views/Backoffice/BackofficePaymeOrderRefund.vue')
  },
  {
    path : '/backoffice/payme_order_ty',
    name : 'BackofficePaymeOrderTy',
    component : () => import('../views/Backoffice/BackofficePaymeOrderTy.vue')
  },
  {
    path: '/hp',
    component : () => import('../views/Hp.vue')
  },
  {
    path: '/temp',
    component : () => import('../views/Temp.vue')
  },
  {
    path: '/',
    component : () => import('../views/ComingSoon.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    component : () => import('../views/404.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
